.keypadRoot {
  @apply w-full px-4 pb-1;
  /* border: 1px solid white; */
}

.slideInTop {
  @apply animate-slideInBottom;
}

.defaultOptions {
  @apply flex justify-between items-center w-full;
}

.option {
  @apply bg-white  min-h-[2rem] flex justify-center rounded-[6px] items-center  w-[23.5%] border border-solid border-stakePannel-purpleBlue100 !bg-stakePannel-purpleBlue50 shadow-customKeyBoardBtnShadow;
  /* border: 1px solid black; */
}

.keyValues {
  @apply w-full flex items-center justify-between flex-wrap mt-[0.3rem];
}
.key {
  @apply shadow-customKeyBoardBtnShadow border border-solid border-stakePannel-purpleBlue100 !bg-stakePannel-purpleBlue50;
  @apply bg-white  px-2 w-[32%] min-h-[2.4rem] my-1 rounded-md flex items-center justify-center;
  /* border: 1px solid black; */
}
.key:active {
  transform: scale(0.99);
  box-shadow: 0 0 0 transparent; /* Removes shadow to emphasize overlap */
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.key:active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Gray overlay */
  pointer-events: none;
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.disbaledClick {
  background-color: rgba(0, 0, 0, 0.4) !important;
  pointer-events: none;
}

.removeBg {
  @apply bg-transparent;
}

.highLightBox {
  @apply relative z-10 animate-zoomInOut;
  transition: transform 0.1s ease-in-out; /* Smooth transition */
  border: 1px solid gray;
  pointer-events: unset !important;
}
