/* Note Using Max Height helps in animation ups and  down but as gave fit-height 
the max height units wont effect the ui if its highh */

.selectedStakeOptionsRoot {
  @apply absolute left-0 bottom-0 z-30 text-primary700 h-fit w-full;
}
.addPaddingBottomg {
  /* @apply !pb-[1rem]; */
}

.rotateDown {
  @apply rotate-180;
  transition: all 0.2s ease-in-out;
}

.rotateUp {
  @apply rotate-0;
  transition: all 0.2s ease-in-out;
}

.betRef {
  display: flex;
  position: relative;
  bottom: -0.8rem;
}
/* stake Pannel Animation handling starts from here... */

.animationRoot {
  @apply bg-white rounded-t-lg pb-[0.3rem] animate-slideInBottom;
  max-height: 0;
  transition: all 0.8s ease-in-out;
  overflow: hidden;
}

.selectedOddsInputsView {
  max-height: 22.5rem;
  transition: all 0.5s ease-in-out;
}
.selectedOddsLabelsView {
  max-height: 10rem;
}

.betPlaceView {
  max-height: 50rem;
  transition: all 0.5s ease-in-out;
}

.selectedOddsInputsAndKeypadView {
  /* animation: increaseHeightToKeypadView 0.5s ease-in-out; */
  max-height: 40rem;
  overflow: hidden;
}

.slideFromLeft {
  @apply animate-slideInLeft;
}

.addPaddingtop {
  @apply pt-[0.8rem];
}
.selectedStakeOptionsRoot > p {
  @apply text-primary700;
}
.betPlaceBar {
  @apply bg-[#1d1030] transition-all duration-[0.2s] ease-[ease-in-out];
  @apply w-full flex items-center justify-center min-h-[4.5rem];
}
.betPlaceBar img {
  @apply mr-3 w-8;
}
.stakeToReturn {
  @apply flex items-end flex-col relative w-[45%] mb-auto bottom-4;
}

.stakeToReturn .row {
  @apply flex justify-around w-full pb-[1.59rem];
}

.stakeToReturn .row div {
  @apply flex flex-col items-end;
}

.stakeToReturn div > p:nth-child(2) {
  @apply pt-2;
}

.row,
.col {
  @apply w-full;
}

.col {
  @apply flex items-center justify-between;
}

.marketDetails {
  @apply gap-4 flex w-full justify-start;
}

.marketDetails div {
  @apply flex gap-2;
}
.teamNameDetails {
  @apply flex gap-2;
}
.flexColDirection {
  gap: 0.2rem;
  flex-direction: column;
  width: 100%;
}

.arrow {
  @apply absolute right-4 bottom-[-1] top-[1rem];
  width: 1rem;
  height: 0.8rem;
  background-color: white;
  z-index: 999;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes centerArrow {
  from {
    transform: rotate(0deg);
    top: 1rem;
  }
  to {
    top: 3rem; /* Final position */
    transform: rotate(180deg);
  }
}

.centerFlatArrowDown {
  animation: centerArrow 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) forwards; /* Use 'forwards' */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.inputWidth {
  @apply ml-auto mr-[0.8rem] flex flex-col items-start justify-center w-[30%] relative mb-auto top-[-0.7rem];
}
.inputWidth > div {
  @apply mt-[0.3rem] ml-auto mr-[0.2rem];
}

.row .col:nth-child(1) {
  @apply flex;
}
.row .col:nth-child(2) > div:nth-child(1) {
  @apply mb-auto w-[50%];
}

.numbersKeyPad {
  /* border: 1px solid black; */
  margin-top: 0.8rem;
  overflow: hidden;
  height: 0rem;
}

.showKeypadView {
  animation: showFullKeyPad 0.5s ease-in-out forwards;
}

.removeShowKeypadView {
  animation: hideFullKeyPad 0.5s ease-in-out forwards;
}

@keyframes showFullKeyPad {
  from {
    height: 0rem;
  }
  to {
    height: 14rem;
  }
}

@keyframes hideFullKeyPad {
  from {
    height: 14rem;
  }
  to {
    height: 0rem;
  }
}

/*  */
.stakeInputsContainer {
  @apply max-h-[14.2rem] flex-grow overflow-y-scroll;
  transition: all 0.2s ease-in-out;
}

.stakeInputsContainerForBetReceipts {
  @apply !max-h-72;
  transition:  all 0.2s ease;
}

/* the height was 7.5rem before  */
.stakeInputsList {
  @apply px-[1rem] pb-[0.6rem] pt-[0.3rem] h-fit max-h-32;
  /* border-top: 1px solid rgba(25, 100, 213, 0.7);
  border-style: dashed */
}

.stakeInputsList:not(:first-child){
  border-top: 1px solid rgba(25, 100, 213, 0.7);
  border-style: dashed
}

.addMinHeightToList {
  @apply !h-[7.5rem];
}

/* .stakeInputsContainer > div:not(:first-child) {
  border-top: 1px solid rgba(25, 100, 213, 0.7);
  border-style: dashed;
} */

.addBottomSpacingWithDashedBorder {
  padding-bottom: 0.6rem;
  /* border-bottom: 1px solid rgba(25, 100, 213, 0.7); */
  border-style: dashed;
}

.row:nth-last-child(1) {
  @apply pt-3;
}
.hide {
  @apply !hidden;
}

.flexSubCol {
  @apply flex  py-[0.25rem] items-center;
  /* background-color: red */
}

.flexSubCol img {
  @apply ml-[0.3rem];
}

.addGap4 {
  @apply gap-4;
}

.col:nth-child(4) {
  @apply py-2;
}

.addTopSpacing {
  @apply py-2;
}

.visibility {
  @apply invisible;
}

.backButtonImage {
  @apply mx-2;
}

.closeIcon {
  @apply relative top-[1.2rem] mb-auto;
}

/* selected stake UI Styling */
.selectedStakeContainer {
  @apply px-4 pb-2;
}
.counterContainer {
  @apply flex items-center gap-3;
}
.selectedStakeContainer .counter {
  @apply bg-black flex items-center justify-center w-7 h-7 rounded-[50%];
}
.teamListContainer {
  display: flex;
  flex-wrap: wrap;
}
.selectedTeamList {
  @apply flex items-center pt-1;

  width: fit-content;
}

.selectedTeamList:not(:last-child)::after {
  content: " , ";
  @apply font-avenirNextBold mx-[0.15rem];
}

.selectedTeamList .selectedMarketsLabels {
  @apply flex items-center justify-center gap-[0.4rem];
}

.addLeftMargin {
  @apply ml-2 mr-[0.1rem] flex items-center;
}

.addLeftMargin p:nth-child(1) {
  position: relative;
  bottom: -0.1px;
}

.addLeftMargin img {
  width: 12px;
}

.highLightBox {
  @apply relative z-10 animate-zoomInOut;
  transition: transform 0.1s ease-in-out; /* Smooth transition */
  pointer-events: unset !important;
}

.moreText {
  @apply text-xl font-degularBold;
}

.placeBetRow {
  @apply flex items-center justify-center mr-[-1.1rem];
}

.conditionalLoading {
  @apply opacity-0;
  transition: all 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.activeLoader {
  @apply opacity-70;
  transition: all 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.visibility {
  @apply opacity-0;
  transition: all 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.loaderContainer {
  @apply absolute right-[2.5rem];
}

.disableClick {
  @apply !pointer-events-none;
}

.addMoreBetsContainer {
  @apply !bg-black z-100;
}

.placeBetBtn{
  @apply px-[0.9rem]
}
