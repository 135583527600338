.carouselContainer {
  @apply relative ;
}

.appCarousel {
  @apply flex overflow-x-auto gap-3 scroll-smooth;
}

.carouselArrowContainer {
  @apply flex flex-row justify-center items-center gap-6 pt-1 absolute top-[11.6rem] max-xs:top-[11.2rem] left-1/2 transform -translate-x-1/2 w-full;
}

.carouselArrowImage {
  @apply cursor-pointer;
}

.carouselArrowImageRotate {
  @apply rotate-180;
}
