.root {
  @apply font-black flex items-center mx-auto rounded-md text-center;
}

.roundButton :nth-child(1) {
  @apply w-24 h-10 rounded-full flex items-center justify-center;
}

.roundedActiveBtn :nth-child(1) {
  @apply !bg-lightGreen600 text-black rounded-full;
}

.roundedInActiveBtn :nth-child(1) {
  @apply !bg-primary700 rounded-full;
}

.inactiveText > button > :nth-child(1) {
  @apply text-gray500;
}

.activeText button > :nth-child(1) {
  @apply text-primary700;
}

.outer {
  @apply w-full flex items-center justify-center border-2 border-primary800 bg-primary700 rounded-md;
}

.outer .kits,
.outer .badges {
  @apply overflow-hidden transition-[width] duration-500 ease-in-out;
}

.outer .kits button,
.outer .badges button {
  @apply w-full outline-none border-none p-1 bg-transparent;
}

.outer .kits button p,
.outer .badges button p {
  @apply text-gray500 relative z-100 font-bold;
}

.clickedText > button > p {
  @apply !text-primary700;
}

.outer .kits,
.outer .badges {
  @apply w-1/2 h-full relative;
}

.kits .boxWrapper {
  @apply bg-primary800 h-full absolute right-0 z-20 rounded-l-sm;
}

.badges .boxWrapper {
  @apply bg-primary800 h-full absolute left-0 z-20 rounded-r-sm;
}

.kits .inactiveTab {
  @apply bg-primary700 h-full absolute left-0 z-20 rounded-r-sm;
}

.badges .inactiveTab {
  @apply bg-primary700 h-full absolute left-0 z-20 rounded-l-sm;
}

.boxTwoClicked {
  @apply animate-clickOnBoxTwo transition duration-[300ms] ease-in-out;
}

.boxOneClicked {
  @apply animate-clickOnBoxOne transition duration-[300ms] ease-in-out;
  animation-delay: 200ms;
}

.reverBoxTwoStyles {
  @apply animate-reverseBoxTwo transition duration-[300ms] ease-in-out;
}

.reverBoxOneStyles {
  @apply animate-reverseBoxOne transition duration-[300ms] ease-in-out;
  animation-delay: 200ms;
}

.hideEl {
  @apply hidden;
}

.activeTabStyles {
  @apply !bg-primary800  text-primary700;
}

.activeTabStyles > button {
  @apply !bg-primary800  text-primary700;
}

.activeTabStyles > button > p {
  @apply !text-primary700;
}
