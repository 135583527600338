.root {
  @apply mb-4;
}
.colRowContainer {
  @apply p-3;
}

.colRowContainer .row {
  @apply w-full;
  display: flex;
  justify-content: space-between;
}
.colRowContainer .row:nth-child(1) .col {
  @apply w-[30%];
  text-align: center;
  margin-bottom: 1rem;
}

.colRowContainer .boxRowContainer {
  display: flex;
  flex-direction: column;
}

.colRowContainer .boxRowContainer .boxRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.colRowContainer .boxRowContainer .col {
  width: 30%;
  display: flex;
  justify-content: center;
}

.alignLeft {
  justify-content: flex-start !important;
}

.marginBottom {
  @apply !mb-4;
}
