.statsCardRoot {
  @apply w-full pr-[0.4rem] pl-[0.2rem] py-[0.1rem] bg-[rgba(5,95,203,0.9)];
}
.teamDetails {
  @apply bg-secondary900 flex justify-end items-center gap-4 px-2 rounded-t-xl;
}

.teamIconDiv {
  @apply bg-blue800 flex justify-center items-center gap-1.5;
}

.oppoTeamDetails {
  @apply flex-row-reverse;
}

.oppBg {
  @apply pl-[0.4rem] pr-[0.2rem];
}

.hide {
  @apply hidden;
}

.statsCardRoot .row {
  @apply flex items-center justify-between text-center w-full;
}

.divider {
  @apply h-[0.8rem] border-[0.1px] border-solid border-[rgba(255,255,255,50%)];
}

.statsRow {
  @apply flex items-center justify-between;
}
.statsRow img {
  @apply w-[0.65rem] h-[0.65rem] mr-[1.5px];
}
