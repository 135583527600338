.root {
  @apply gap-[1.2rem];
}

.root .row:nth-child(1) {
  @apply flex items-center justify-center;
}
.root .row:nth-child(2) {
  @apply flex items-center justify-center py-4;
}

.list {
  @apply flex items-baseline my-[0.15rem];
}

.row > p:nth-child(2) {
  @apply py-5;
}

.list p:nth-child(1) {
  @apply px-[0.6rem];
}

.icon {
  @apply w-14;
}

.fontBlack{
  @apply font-black
}
