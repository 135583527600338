.root {
  @apply flex flex-col !justify-start items-start;
}

.subRoot {
  @apply py-[0.4rem] pl-[0.5rem]  text-white rounded-lg;
  transition: all 0.2s ease-in-out;
}
.addTopSpacing {
  @apply mt-[0.2rem];
}

.colReverse {
  @apply flex flex-col-reverse;
}

.inputContainer {
  @apply flex items-center text-center;
  /* border: 1px solid black;  */
}
.input {
  @apply leading-[18px] font-semibold bg-transparent focus:border-[none] font-avenirNextBold w-full text-white text-lg;
}

input:focus {
  outline: none;
}

.addBorder {
  @apply border-4 border-solid border-neutral900 p-3;
}

.purpleBlueBorder {
  @apply border-[3px] border-solid border-stakePannel-purpleBlue;
}

.lightGrayBorder {
  @apply border-[1px] border-solid border-stakePannel-purpleBlue500;
}

.root > p:nth-child(1) {
  @apply opacity-65;
}

.addBoxShadow {
  @apply shadow-[inset_0px_2.233px_0px_0px_rgba(0,0,0,0.2)];
}

.hide {
  @apply !hidden;
}

.purpleBlue700 {
  @apply !text-stakePannel-purpleBlue700;
}



.purpleBlue500 {
  @apply !text-stakePannel-purpleBlue500;
}

.purpleBlue {
  @apply !text-stakePannel-purpleBlue;
}

.startIcon {
  @apply pr-0 flex items-center h-4;
}

.lightGrayBg {
  @apply !bg-stakePannel-lightGrayBg;
}

.textLg {
  @apply text-sm;
}
.textXs {
  @apply text-xs;
}

.stakeInputHeight {
  @apply h-[3.3rem];
}

.placeholderDecimal {
  @apply text-stakePannel-purpleBlue500  font-semibold font-avenirNextBold  text-lg;
}
.textDisplay {
  position: absolute;
}

.hideVisibility {
  @apply opacity-0;
}

.red {
  @apply text-red-600;
}

.addLeftMargin {
  @apply ml-[0.rem];
}
