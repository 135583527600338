.root{
    /* @apply px-[1.2rem]; */
}

.disableLayer{
    position: relative;
    box-sizing: border-box;
    @apply px-[1.2rem];
}

.disableLayer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent layer */
    z-index: 1; /* Layer's stacking order */
  }