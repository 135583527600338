.mainContainer {
  @apply bg-desktopBg bg-no-repeat bg-cover;
}

.childContainer {
  @apply flex flex-col overflow-y-auto max-w-[480px] mx-auto justify-center items-center bg-primary700 bg-no-repeat landscape:relative landscape:overflow-hidden bg-[length:100%_100%];
  height: calc(var(--vh, 1vh) * 100); /* Use dynamic viewport height */
}

.children {
  @apply flex-1 w-full overflow-x-hidden;
  height: calc(var(--vh, 1vh) * 100 - 9.5rem); /* Dynamically calculate the height based on the available viewport */
}

.header {
  @apply w-full;
}

.notify {
  @apply hidden z-100;
}

@media (max-width: 940px) {
  @media (orientation: landscape) {
    .notify {
      @apply !absolute left-0 right-0 top-0 bottom-0 bg-black overflow-hidden flex justify-center items-center;
    }
  }
}

/* .notify {
  @apply flex portrait:!hidden justify-center items-center landscape:!absolute z-100 left-0 right-0 top-0 bottom-0 landscape:bg-black landscape:overflow-hidden
} */
