.root {
  @apply mb-4;
}
.colRowContainer {
  @apply p-3;
}
.colRowContainer .row {
  @apply flex justify-between;
  @apply w-full;
}
.colRowContainer .row .col {
  @apply text-center mb-4;
  @apply !w-[17%];
}
.colRowContainer .row .col:nth-child(1) {
  @apply text-center mb-4;
  @apply !w-[50%];
}
.colRowContainer .row .col:nth-child(3) {
  @apply mx-[0.3rem];
}

.playerDetails {
  @apply flex items-center justify-start flex gap-[0.4rem];
}
.playerDetails .attRating {
  @apply w-[2rem] h-[2rem] flex flex-col items-center justify-center rounded-[0.2rem];
}
.playerDetails .teamDetails {
  @apply text-left w-[70%];
  /* border: 1px solid white; */
}

.playerDetails .teamDetails p:nth-child(1) {
  @apply mb-1;
}

.bgBronze {
  @apply bg-AttBgColor-bronze;
}
.bgSilver {
  @apply bg-AttBgColor-silver;
}
.bgGold {
  @apply bg-AttBgColor-gold;
}
.bgInForm {
  @apply bg-AttBgColor-inform;
}

.showMoreContainer {
  @apply flex items-center justify-center gap-2 pb-2;
}

.showMoreContainer > p {
  @apply font-avenirNextRegular text-white700;
}

.rotateIcon {
  @apply rotate-180;
}
