.loader-container {
  @apply h-full min-h-[180px] flex items-center justify-center;
}

.removeHeight {
  min-height: fit-content;
}
.isAddMinHeight{
  @apply !min-h-44
}
.btnLoader {
  width: 55px !important;
}

.hide{
  @apply hidden;
}