.wrapper {
  max-width: 480px;
  margin: auto;
  background: url("/public/images/team/bg.jpg") no-repeat center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 10px;
}

.firstDiv {
  @apply flex flex-col items-center;
}

.rows {
  @apply flex flex-row items-center justify-around;
}

.cols {
  @apply flex flex-col items-center gap-y-1;
}

.myBets {
  @apply w-full flex flex-col rounded-md mt-8 max-h-[550px] overflow-x-hidden overflow-y-auto;
  background-color: rgba(28, 32, 80, 80%);
  border: 1.5px solid rgba(25, 100, 213, 0.7);
}

.header {
  @apply w-full py-2 rounded-t-md text-left;
  background-color: rgba(28, 32, 80, 70%);
}

.header > p {
  @apply pl-3;
}

.wrap {
  @apply flex flex-row justify-between py-[10px] px-1;
}

.teamName {
  @apply w-[85%] flex flex-row items-center justify-between mt-1 px-3;
}

.playerName {
  @apply w-[85%] flex flex-row items-start justify-between mt-3 px-6;
}

.marketDetails {
  @apply flex flex-row gap-x-2 justify-between w-full;
}

.isResolvedImg {
  @apply h-5 w-5;
}

.marketCol {
  @apply flex w-full justify-start pt-1;
}

.betDetails {
  @apply flex items-baseline gap-2;
}

.goalsOverUnder {
  @apply flex flex-row gap-1;
}

.flexCol {
  @apply flex flex-col gap-1;
}

.hideEl {
  @apply !hidden;
}
