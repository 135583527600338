.betSlipRoot {
  @apply !overflow-hidden bg-betPannelOptionsWaterMarkBg;
  /* border: 2px solid red; */
  height: calc(100% - 0.2rem);
}

.betSlipHeader {
  @apply h-[2.75rem] w-full  flex items-center justify-between pl-[1rem];
}

.betSlipHeader .tab {
  @apply rounded-[8px] h-[44px]  animate-fadeInSmooth w-[45%]  p-[6px] flex items-center justify-center;
  transition: all 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border: 1.6px solid rgba(25, 100, 213, 0.7);
}

.inActiveTab {
  filter: grayscale(10%) brightness(50%);
}
.activeTab {
  @apply !bg-[rgba(28,32,80,70%)];
}

.betsFilterList {
  @apply px-4 py-4 flex overflow-x-scroll gap-1 items-center ml-[0rem];
  transition: all 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.active {
  @apply bg-lightGreen600;
  transition: all 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.betsFilterList .list {
  @apply px-4 py-2 rounded-2xl;
}

.hide {
  @apply !hidden;
}

.betSlipContainer {
  @apply flex flex-col px-4 py-5;
  height: calc(100% - 7rem);
  overflow: scroll;
  scroll-behavior: smooth;
}

.centerItems {
  @apply flex justify-center items-center;
}

.centerErrorMsg {
  @apply h-full flex items-center justify-center animate-fadeInSmooth;
}

.showHalfOlderTab {
  @apply ml-[-2rem];
}

.showFullOlderTab {
  @apply ml-[-3rem];
}

.dateSlipHeader .dateTime {
  @apply flex justify-center mb-6;
}

.loadMore {
  @apply flex items-center justify-center;
}

.loadMore > button > p:nth-child(1) {
  @apply py-1.5;
}
