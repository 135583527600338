.box {
  @apply flex flex-col box-border justify-between h-full;
}

.root {
  @apply px-6 pt-4 flex flex-col gap-4 max-xs:gap-3;
}

.version {
  @apply mb-2 mx-6 box-border flex justify-between items-center;
}

.settingsDiv {
  @apply w-full h-16 bg-neutral900 flex items-center pl-6;
}

.backGround {
  @apply w-full h-full bg-neutral800 relative text-white rounded-md;
}

.upperRect {
  @apply w-full h-9 bg-neutral900 rounded-t-md flex items-center pl-4;
}

.upperRect > p {
  @apply text-base;
}

.firstDiv {
  @apply flex justify-between items-center pt-2 max-xs:pt-1;
}

.secondDiv {
  @apply flex flex-row justify-between px-6 py-2 items-center;
}

.secondDiv > p {
  @apply text-base font-semibold;
}

.flexRow {
  @apply flex flex-row gap-2;
}

/* .flexRow > p {
  @apply text-gray500 font-semibold text-base;
} */

.borderOddsDisplay {
  @apply border-neutral600 my-1 mx-[1.5rem] last:border-none  border-b-[1px];
}

.marginBtm {
  @apply mb-2 max-xs:mb-1;
}

.radioContainer {
  @apply relative inline-block cursor-pointer;
}

.hiddenRadio {
  @apply absolute opacity-0 cursor-pointer;
}

/* .selected > p {
  @apply !text-white;
} */

.customRadio {
  @apply relative inline-block w-6 h-6 rounded-full border-2 border-white/50 transition-colors duration-300 ease-in-out;
}

.customRadio::after {
  @apply content-[""] absolute w-9/12 h-3/4 bg-transparent -translate-x-2/4 -translate-y-2/4 transition-[background-color] duration-[0.3s] ease-[ease] rounded-[50%] left-2/4 top-2/4;
}

.customRadioChecked::after {
  @apply bg-white;
}

.toggleSwitch {
  @apply relative inline-block w-[50px] h-[26px];
}

.hiddenInput {
  @apply opacity-0 w-0 h-0;
}

.slider {
  @apply absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-[#ccc] duration-200 rounded-full;
}

.slider::before {
  @apply absolute content-[""] h-[22px] w-[22px] bg-[white] transition-[0.4s] rounded-[50%] left-0.5 bottom-0.5;
}

.checked {
  @apply bg-primary900 before:translate-x-6;
}

.accordionContainer {
  @apply bg-[#2b2b2b] overflow-hidden mx-0 my-4 rounded-lg;
}

.accordionContent {
  @apply text-[white] px-2;
}

.accordionContent > p {
  @apply font-semibold text-sm;
}

.backBtn {
  @apply h-full w-[0.5rem] -rotate-180;
}

.borderAccordion {
  @apply border-b-[1px] border-neutral600 last:border-none w-[87%] mx-5;
}

.header {
  @apply flex flex-row justify-between pl-7  bg-neutral900 w-full h-16 items-center;
}

.exitButton {
  @apply h-8 w-7;
}

.flexCol {
  /* @apply flex flex-col items-center justify-center sticky bottom-2; uncomment once exit game integration is done */
  @apply flex flex-col items-center justify-center;
}

.flexCol > p {
  @apply leading-5 text-sm;
}

.arrowIcon {
  @apply rotate-180 h-4;
}

.resetIcon {
  @apply w-4;
}

.hide {
  @apply !hidden;
}

.useCheat {
  /* border: 1px solid white; */
}

.useCheat .col {
  @apply flex items-center justify-between gap-2 mb-1;
}

.useCheat .col:nth-child(1) {
  @apply mb-2;
}

.useCheat .col:nth-child(1) .forceBuildInput {
  @apply w-[75%];
}

.forceBuildInput {
  @apply h-12 bg-transparent text-white border-[1px] w-[60%] border-white500 rounded-md px-2 py-[0.50rem] font-degularSemiBold;
}

.useCheat .col:nth-child(2) {
  @apply flex items-center justify-start gap-1;
}
