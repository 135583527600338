.root {
  @apply w-full mb-4;
}

.boxList {
  @apply flex justify-between p-3;
}

.list {
  @apply w-[30%];
}
.list .col {
  /* border: 1px solid white; */
  @apply h-8 text-center flex items-center justify-center;
}

.highLightBox {
  @apply relative z-10 animate-zoomInOut;
  transition: transform 0.1s ease-in-out; /* Smooth transition */
  pointer-events: unset !important;
}
