@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overscroll-behavior: none !important;
  overflow: hidden !important;
  margin: 0 auto;
}

.react-multi-carousel-dot-list {
  left: 8% !important;
  bottom: 18% !important;
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "Degular-Regular";
  src: url("/public/font/Degular/DegularDisplay-Regular.otf");
}

@font-face {
  font-family: "Degular-Medium";
  src: url("/public/font/Degular/DegularDisplay-Medium.otf");
}

@font-face {
  font-family: "Degular-Bold";
  src: url("/public/font/Degular/Degular-Bold.ttf");
}

@font-face {
  font-family: "Degular-semiBold";
  src: url("/public/font/Degular/DegularDisplay-semiBold.otf");
}

@font-face {
  font-family: "Degular-Black";
  src: url("/public/font/Degular/DegularDisplay-Black.otf");
}

@font-face {
  font-family: "avenirNext-Regular";
  src: url("/public/font/AvenirNext/AvenirNextLTPro-Regular.otf");
}

@font-face {
  font-family: "avenirNext-Medium";
  src: url("/public/font/AvenirNext/AvenirNextCyr-Medium.ttf");
}

@font-face {
  font-family: "avenirNext-Bold";
  src: url("/public/font/AvenirNext/AvenirNextLTPro-Bold.otf");
}
@font-face {
  font-family: "avenirNext-semiBold";
  src: url("/public/font/AvenirNext/AvenirNextLTPro-Demi.otf");
}

@font-face {
  font-family: "avenirNext-Black";
  src: url("/public/font/AvenirNext/AvenirNextLTPro-HeavyCn.otf");
}

.notistack-MuiContent-info {
  @apply bg-red-600 !important;
}
