.header {
  @apply bg-championBg bg-cover w-full bg-no-repeat h-fit flex flex-row justify-between px-2 border-b-2 border-b-[#BEEAF8] max-2xs:px-1 max-2xs:py-1;
}

.header p {
  @apply flex flex-col gap-2 max-2xs:gap-0;
  text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.463);
}

.header p span {
  @apply flex items-center justify-center;
}

.division {
  @apply font-degularBold text-3xl;
}

.champion {
  @apply font-degularBold text-[2.5rem];
}

.championStar {
  @apply h-20 max-2xs:h-16;
}

.divWrap {
  @apply flex flex-col w-full px-4 pt-2 gap-2 h-full max-2xs:pb-2 max-2xs:gap-1.5;
  overflow: hidden;
}

.firstDiv {
  @apply w-full flex flex-row items-center gap-3 justify-center;
}

.secondDiv {
  @apply flex flex-row justify-between;
}

.secondDiv p {
  @apply max-2xs:!text-xs;
}

.secondDiv > div {
  @apply flex flex-row gap-3.5 ml-3  w-[50%];
}

.rankText {
  @apply w-[14%];
}

.thirdDiv {
  @apply flex flex-col gap-[0.3rem] max-2xs:overflow-y-auto h-full max-2xs:max-h-[400px];
}

.leaderBoardContainer {
  @apply h-full overflow-y-scroll;
}
.leaderBoardRow {
  @apply pt-[1.5px] rounded-md w-full shadow-lg mb-[0.6rem] h-[2.3rem] bg-leaderBoardRowBg;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* Parent container fix */
  overflow: visible; /* Important */
  position: relative;
  border-radius: 8px; /* Keeps parent rounded */
  display: flex;
  align-items: center;
}

/* General Column Styles */
.leaderBoardRow .col:nth-child(1),
.leaderBoardRow .col:nth-child(2),
.leaderBoardRow .col:nth-child(4),
.leaderBoardRow .col:nth-child(5) {
  @apply w-[15%] h-full flex items-center justify-center;
}

.leaderBoardRow .col:nth-child(3) {
  @apply w-[43%] h-full flex items-center;
}

.leaderBoardRow .col:nth-child(5) {
  @apply bg-rankStarBg bg-contain bg-no-repeat bg-center pt-[0.12rem];
}

.leaderBoardRow .col:nth-child(5) > p {
  @apply pt-[2px];
}

/* Allow Image to Overflow Without Clipping */
.leaderBoardRow .col:nth-child(1) {
  position: relative; /* Establish containing block */
  overflow: visible; /* Ensure overflow is visible */
}

/* Fix Image Overflow with Absolute Position */
.leaderBoardRow .col:nth-child(1) .rankImage {
  position: absolute;
  width: 1.65rem;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* Ensures it's above other content */
  pointer-events: auto; /* Allows interaction */
}

/* Badge Image */
.leaderBoardRow .col:nth-child(2) img {
  @apply h-[86%];
}

.leaderBoardRow .col:nth-child(4) {
  @apply justify-end;
}
.leaderBoardRow .col:nth-child(2) {
  @apply justify-center w-[11%] pr-1;
}
.leaderBoardRow .col:nth-child(1) {
  @apply justify-center w-[14%];
}
/* Team Logo */
.leaderBoardRow .col:nth-child(4) img {
  @apply h-[50%];
}

.activeBlueBg {
  @apply bg-userRankingBg z-70 sticky bottom-0;
}
.isSticky {
  @apply bg-primary700 sticky bottom-0 pt-[0.15rem];
}
.tabBorder {
  @apply bg-leadrBoardLinear p-[0.9px] rounded-[4px] w-36 max-2xs:w-32;
}

.tab {
  @apply bg-primary700 rounded-[4px] flex justify-center items-center gap-2  animate-fadeInSmooth py-1;
  transition: all 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.tab p {
  @apply max-2xs:text-xs;
}

.activeTab {
  @apply bg-activeTabBg;
}

.myTeamRow {
  @apply bg-leadrBoardLinear pt-[1.5px] rounded-md w-full shadow-xl mt-3 max-2xs:mt-2;
}

.myTeamRowInnerDiv {
  @apply grid grid-cols-7 gap-1.5  bg-myTeamBg w-full h-[2.2rem] rounded-md items-center relative max-2xs:h-7;
}

.myTeamRowInnerDiv > :nth-child(1) {
  @apply !justify-self-center pl-2;
}

.myTeamRowInnerDiv > :nth-child(2) {
  @apply py-0.5 justify-self-center;
}

.myTeamRowInnerDiv > :nth-child(3) {
  @apply col-span-3;
}

.myTeamRowInnerDiv > :nth-child(4) {
  @apply !justify-self-center;
}

.myTeamRowInnerDiv > :nth-child(5) {
  @apply !justify-items-start;
}

.spinner {
  @apply flex justify-center items-center w-full h-full;
}
