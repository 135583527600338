.root {
  @apply relative h-full w-full box-border;
}

.default {
  @apply absolute z-10 top-0 bottom-0 w-full bg-primary700;
}

.hide {
  @apply !hidden;
}

.noWidth{
  @apply w-0;
}