.mainDiv {
  @apply flex flex-col h-full justify-around;
}

.firstDiv {
  @apply flex flex-row gap-1 items-center justify-center pt-1;
}

.teamNameInput {
  @apply max-w-[80%] min-w-[80%] text-2xl font-bold bg-transparent rounded-md text-white outline-none ease-in-out duration-300 overflow-hidden font-degularSemiBold border-transparent text-center;
}

.teamNameInput:focus {
  @apply outline-none border-none;
}

.editButton {
  @apply w-5 h-5;
}

.secondDiv {
  @apply flex flex-row justify-center pt-2 gap-7 items-center;
}

.tshirtImg {
  @apply w-[8rem] h-40  max-2xs:w-[7.5rem] max-2xs:h-[9.5rem] max-xs:w-[7.5rem] max-xs:h-36 mr-6;
  filter: drop-shadow(3px 13px 30px rgba(0, 0, 0, 0.25)) drop-shadow(13px 52px 54px rgba(0, 0, 0, 0.21))
    drop-shadow(30px 118px 73px rgba(0, 0, 0, 0.13)) drop-shadow(54px 210px 87px rgba(0, 0, 0, 0.06))
    drop-shadow(84px 327px 95px rgba(0, 0, 0, 0));
}

.emblemImg {
  @apply w-[5.5rem] h-auto mr-[0.8rem];
}

.tabs {
  @apply px-10 pt-3 max-2xs:pt-2 max-xs:pt-1;
}

.carousel {
  @apply bg-black/50 border-neutral500 border-2 rounded-md flex flex-row gap-4 w-28 h-44 items-center justify-center  max-2xs:w-[6.4rem] max-2xs:h-40;
}

.selected {
  @apply !border-2 border-white;
}

.carouselKitImg {
  @apply w-24 h-32 max-xs:h-[7.5rem] max-2xs:w-[5.5rem];
}

.carouselEmblemImg {
  @apply w-[4.5rem] h-auto;
}

.selectButton {
  @apply flex justify-center h-10 mb-2;
}

.thirdDiv {
  @apply mt-3 max-xs:pt-0 relative;
}

.arrows {
  @apply h-[14rem] max-xs:h-[12.6rem] flex items-start;
}
