.root {
  @apply w-full mb-4;
}

.boxList {
  @apply p-3;
}

.list {
  @apply w-[100%] flex items-center justify-between;
}

.list .col {
  width: 30%;
}

.marginBottom {
  @apply mb-4;
}
