.root {
  @apply font-degularRegular  p-0 m-0 font-normal text-base transition-all duration-[0.2s] ease-in-out;
}
.addEllipise {
  @apply !text-ellipsis !overflow-hidden;
}

.avenirFont {
  @apply !font-avenirNextRegular;
}

.avenirNextMedium {
  @apply !font-avenirNextMedium;
}

.avenirNextBold {
  @apply !font-avenirNextBold;
}

.avenirNextSemiBold {
  @apply !font-avenirNextSemiBold;
}

.avenirNextBlack {
  @apply !font-avenirNextBlack;
}

.degular {
  @apply font-degularRegular;
}

.degularMedium {
  @apply !font-degularMedium;
}

.degularBold {
  @apply !font-degularBold;
}

.degularSemiBold {
  @apply !font-degularSemiBold;
}

.degularBlack {
  @apply !font-degularBlack;
}

.font900 {
  @apply !font-black;
}

.font800 {
  @apply font-extrabold;
}

.font700 {
  @apply !font-bold;
}

.navyBlue {
  @apply text-primary700;
}
.yellow {
  @apply text-yellow;
}

.gray {
  @apply text-[rgba(255,255,255,50%)];
}

.grayPlus {
  @apply text-[rgba(255,255,255,70%)];
}

.blackishGray {
  @apply text-[rgba(29,16,48,50%)];
}

.white {
  @apply text-white;
}

.green {
  @apply text-[#02F5AC];
}
.transform {
  @apply uppercase;
}

.normal {
  @apply font-medium;
}

.lg {
  @apply text-lg;
}

.lg_noLineHeight {
  @apply !text-lg !leading-[0px] text-center my-auto;
}

.md {
  @apply !text-[16px] leading-[16px];
}

.md2 {
  @apply !text-[14px] leading-[15.5px];
}

.md3 {
  @apply !text-[13px] leading-[14.5px];
}

.xs {
  @apply text-xs tracking-[0.4px];
}

.xs1 {
  @apply !text-[11px] leading-[12.5px];
}

.xs2 {
  @apply !text-[10px] leading-[11px];
}

.xs2_5 {
  @apply !text-[9.5px] leading-[9.5px];
}

.xs3 {
  @apply !text-[8px] leading-[8px];
}

.xs4 {
  @apply !text-[7.5px] leading-[7px];
}

.xl {
  @apply text-xl leading-none;
}

.xl1 {
  @apply text-[20px] leading-none;
}

.xl2 {
  @apply text-2xl;
}

.xl3 {
  @apply text-3xl;
}

.hide {
  @apply !hidden;
}

.hideVisibility {
  @apply opacity-0;
}

.black {
  @apply text-black;
}

.addSpaceToRight {
  @apply pr-2;
}

.noWrap {
  @apply whitespace-nowrap;
}

.wrap {
  @apply !whitespace-pre-wrap;
}

.positionLeft {
  @apply flex justify-start;
}

.purple {
  @apply !text-stakePannel-purple;
}

.purpleBlue {
  @apply text-stakePannel-purpleBlue;
}

.purpleBlue700 {
  @apply text-stakePannel-purpleBlue700;
}

.purpleBlue300 {
  @apply text-stakePannel-purpleBlue300;
}

.capitalize {
  text-transform: capitalize !important;
}

.whiteSpacePreWrap {
  white-space: pre-wrap;
}

.noLineHeight {
  @apply !leading-[18px];
}
