.boxRoot {
  @apply min-h-[3rem] w-full max-h-fit flex text-center items-center justify-center flex-col border-[1px] border-solid border-[rgba(25,100,213,70%)]  bg-[#160F2A] px-4 rounded-lg overflow-hidden;
  transition: all 0.5s ease-in-out;
}
.boxRoot p {
  @apply whitespace-nowrap;
}

.active {
  @apply border-[2.5px] border-solid border-[#02F5AC] bg-[#02F5AC];
}

.activeImg {
  @apply h-[2.5rem];
}
.hide {
  @apply !hidden;
}

.smBoxPadding {
  @apply !px-[0rem];
}

.disableClick {
  @apply pointer-events-none;
}

.avoidClicking {
  @apply !pointer-events-none;
}

.smallSizeLoader {
  @apply w-6;
}

.imgIcon {
  @apply w-6;
}

.fixedHeight {
  @apply !h-[3rem] !max-h-[3rem] !min-h-[3rem];
}

.boxInnerContent{
  @apply flex flex-col items-center justify-center w-full;
}