.body {
  @apply flex flex-col items-center justify-center gap-2 !m-0;
}

.text {
  @apply flex flex-col gap-1 text-justify;
}

.text > p {
  @apply opacity-80 text-sm leading-5;
}

.btn > button {
  @apply w-28 h-8;
}

.errormsg {
  @apply flex flex-col gap-1 text-center;
}

.errormsg > p {
  @apply opacity-80 text-sm leading-5;
}

.icon {
  @apply w-12;
}

.hide {
  @apply !hidden;
}

.addSuccessGap {
  @apply h-32 gap-8;
}
