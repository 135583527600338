.betSlip {
  @apply rounded-lg h-fit mb-4 animate-fadeInSmooth;
  border: 1.5px solid rgba(25, 100, 213, 0.7);
}

.betSlip header {
  @apply bg-[rgba(28,32,80,70%)] h-[3.75rem] flex items-start justify-center flex-col gap-2 rounded-t-lg pl-4;
}

.betSlipItems {
  @apply px-2 py-4 flex items-start justify-start;
}

.betSlipItems .col:last-child {
  @apply flex flex-col items-end gap-2 ml-auto;
}

.betSlipItems .col:last-child .row {
  @apply flex items-end flex-col;
}

.betSlipItems .col:last-child p:nth-child(2) {
  @apply mt-1;
}

.betSlipItems .col:nth-child(1) {
  @apply my-auto min-w-6;
}

.betSlipItems .col:nth-child(2) .flexRow {
  @apply flex gap-2;
}

.betSlipItems .col:nth-child(2) .marketDetails {
  @apply my-1;
}

.stakeToReturnSubCol {
  @apply flex  gap-2;
}

.activeStatusIcon {
  @apply w-[1.25rem] h-[1.25rem] mr-2;
}

.hide {
  @apply !hidden;
}

.marketDetails {
  @apply gap-4 flex w-full justify-start py-[0.2rem];
}

.marketDetails div {
  @apply flex gap-2;
}

.flexColDirection {
  @apply flex flex-col gap-1;
}

.teamName {
  @apply flex flex-row items-center gap-2 w-full;
}

.adjustwidth {
  @apply !w-[45%];
}
.coinHistoryItem {
  @apply p-4 flex items-center justify-start gap-3;
}

.coinHistoryItem .col:last-child {
  @apply flex items-center gap-2 ml-auto;
}

.coinHistoryItem .col:last-child p:nth-child(2) {
  @apply mt-[0.35rem];
}
