.root {
  @apply w-full mb-4;
}

.root .boxRowContainer {
  width: 100%;
  display: flex;
  /* border: 1px solid white; */
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  padding: 0.75rem;
}

.root .boxRowContainer .boxCol {
  width: 30%;
}
.root .boxRowContainer .boxCol .col:nth-child(1) {
  @apply flex justify-center items-center h-[1.2rem] max-h-[1.4rem]
}

.root .boxRowContainer .col {
  margin: 0.6rem 0;
}
