.root {
  @apply absolute z-60 w-full h-full bg-black/70 top-0 left-0;
}

.center {
  @apply flex justify-center items-center;
}

.containerBorder {
  @apply bg-dialogBorder p-[2px] flex overflow-hidden overflow-y-auto overflow-x-hidden max-w-[480px] rounded-2xl justify-center m-8;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.container {
  @apply flex flex-col overflow-hidden max-w-[480px]  justify-center w-full  rounded-2xl z-50 bg-dialogBg overflow-y-auto overflow-x-hidden;
}

.large {
  @apply max-h-[70%];
}

.xl {
  @apply max-h-[75%] m-4;
}

.xs {
  @apply max-h-[55%];
}

.modalContent {
  @apply relative shadow text-white animate-slideIn w-full h-full overflow-y-auto overflow-x-hidden p-4 flex flex-col;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.modalContent::-webkit-scrollbar {
  display: none;
}

.modalHead {
  @apply flex items-center justify-between rounded-t-xl;
}

.addBorder {
  @apply w-full border-b-2 border-neutral900 py-1;
}

.modalContent > :nth-child(2) {
  @apply text-xs opacity-80;
}

.modalFooter {
  @apply flex items-center justify-center rounded-b-xl py-2;
}

.modalBody {
  @apply h-auto my-2 overflow-y-auto overflow-x-hidden min-w-[290px];
}

.slideInTop {
  @apply animate-slideInTop;
}

.slideOutTop {
  @apply animate-slideOutTop pointer-events-none;
}

.hideEl {
  @apply !hidden;
}

.hideBg {
  @apply animate-fadeOut;
}

.noMargin {
  @apply !m-0;
}

.noPaddingTop {
  @apply pt-0;
}

.disableScroll {
  @apply overflow-hidden;
}
