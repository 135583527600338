.drawerRoot {
  @apply flex flex-col w-full h-full gap-4 bg-betPannelOptionsWaterMarkBg bg-no-repeat;
}

.disableClick {
}

.disableClick::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent layer */
  z-index: 1; /* Layer's stacking order */
}

.teamVersus {
  @apply flex relative items-center justify-center px-0 h-[64px] bg-center bg-no-repeat bg-cover bg-betPannelHeaderBg;
}
/* up */
.teamVersus .info{
  @apply absolute right-1 top-1 border-white500 border-2 w-[22px] h-[22px] flex items-center justify-center rounded-[4px]; 
} 

.teamVersus .versusContainer {
  @apply w-[15%] flex justify-center items-center;
}
.teamVersus .vs {
  @apply w-[1.5rem] h-[1.5rem] ml-[0.4rem] mr-[0.5rem] my-0;
}
.teamVersus .team:nth-child(1) p {
  @apply text-right;
}
.teamVersus .teamIcon {
  @apply object-cover h-[2.625rem];
}

.team {
  @apply flex items-center justify-end gap-[0.3rem] whitespace-nowrap w-[40%];
}

.rowReverse {
  @apply flex-row-reverse;
}

.teamsCardContainer {
  @apply flex justify-between bg-betPannelSecondaryHeaderBg bg-center  top-0;
}

.stickToTop {
  @apply sticky z-10;
}

.teamsCardContainer > div {
  @apply w-[50%];
}

.betPannelContainer {
  @apply flex flex-col w-full  px-[1.2rem] pt-4 pb-[1.5rem] h-fit;
}

.pannelBox {
  @apply overflow-hidden   mb-[1.2rem] rounded-lg border-[1px] border-solid border-[rgba(25,100,213,70%)] w-full;
}

.pannelBox header {
  @apply flex justify-between items-center bg-[rgba(25,100,213,15%)] px-[1.2rem] py-[0.8rem] border-[1px] border-solid border-[rgba(25,100,213,20%)];
}

.pannelBox .pannelContent {
  @apply h-fit flex items-center justify-around px-3 py-3 flex-col w-full;
}

.pannelContent .row {
  @apply flex justify-between w-full my-[0.15rem] pb-[0.6rem] items-center text-center;
}

.pannelContent .row .col {
  @apply w-[30%] text-left flex items-center gap-2;
}

.pannelContent .row .col .childCol {
  @apply flex  justify-center flex-col;
}

.wordNoWrap {
  @apply whitespace-nowrap;
}

.pannelContent .row > .customStyling:not(.col:nth-child(1)) {
  @apply flex justify-center;
}

.widthLg {
  @apply !w-[42%] flex justify-between;
}

.addBorderBelow:nth-child(1),
.addBorderBelow2ndSection:nth-child(2) {
  @apply border-b-[rgba(25,100,213,70%)] border-b-[1.5px] border-solid m-1;
}

.flexStart {
  @apply !justify-start;
}
.widthxl {
  @apply !w-3/5;
}

.alignCenter {
  @apply flex !items-center !text-center justify-center;
}

.visibilityHidden {
  @apply invisible;
}

.plusMinusBox {
  @apply min-h-[9rem] w-[3rem] max-h-fit flex text-center items-center justify-center flex-col border-[1px] border-solid border-[rgba(25,100,213,70%)] bg-[#160F2A] rounded-lg px-2  shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)] shadow-[0px_0px_6px_0px_rgba(25,100,213,1)_inset];
}
.visibility {
  @apply invisible;
}
.paddingSm {
  @apply px-4 min-h-[2.5rem];
}

.active {
  @apply border-[2.5px] border-solid border-[#02F5AC];
}

.hide {
  @apply !hidden;
}

.spaceAround {
  @apply justify-around;
}

.widthSm {
  @apply !w-[20%] justify-center mx-[0.2rem];
}
.widthMd {
  @apply !w-[25%] justify-center mx-[0.2rem] !px-1;
}

.ShowMoreCol {
  @apply w-full;
}

.ShowMoreRow {
  @apply flex justify-center items-center gap-[0.5rem];
}

.accordionIcon {
  @apply transition-all duration-[0.2s] ease-[ease-in-out];
}
.rotateAccordionIcon {
  @apply rotate-180;
}

.flexNotFound {
  @apply h-full flex items-center justify-center;
}

.startMatchBtn {
  @apply mx-[-1.2rem] mb-[-0.2rem] px-[1.25rem];
}
