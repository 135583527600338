.root {
  @apply mb-4;
}
.colRowContainer {
  @apply p-3;
}

.colRowContainer .row {
  @apply w-full;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid white; */
}
.colRowContainer .row:nth-child(1) .col {
  @apply w-[30%];
  text-align: center;
  margin-bottom: 1rem;
  /* border: 1px solid red; */
}

.colRowContainer .boxRowContainer {
  display: flex;
  flex-direction: column;
  /* border: 1px solid white; */
}

.colRowContainer .boxRowContainer .boxRow {
  /* border: 1px solid white !important; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.colRowContainer .boxRowContainer .col {
  width: 30%;
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
}

.alignLeft {
  justify-content: flex-start !important;
}

.marginBottom {
  @apply !mb-4;
}
