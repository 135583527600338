.vaultContainer {
  border: 2px solid rgba(255, 255, 255, 0.5);
  @apply h-[4.5rem] bg-vaultLinear relative;
}
.badgeLevel {
  @apply absolute right-[0rem] top-[0rem] w-[1.85rem] h-[1.85rem] p-2 rounded-[100%] flex justify-center items-center;
}
.addRedColor{
  @apply bg-red-600;
}
.colRowContainer {
  @apply flex justify-center h-full pl-11 flex-col;
}

.colRowContainer .row:nth-child(1) {
  @apply flex items-center;
}

.colRowContainer .row:nth-child(1) p:nth-child(1) {
  @apply mr-6;
}

.colRowContainer .row:nth-child(2) {
  @apply w-[95%] flex items-end mt-[-0.8rem];
}

.colRowContainer .row:nth-child(2) .outerProgressBar {
  @apply bg-black w-full h-2 rounded-sm flex items-end;
}
.valueBar {
  @apply bg-lightGreen600 h-2 rounded-s-sm;
}
.colRowContainer .row:nth-child(2) .outerProgressBar .innerProgressBar {
  @apply h-2 rounded-s-sm;
  transition: all 0.65s linear; 
}

.pointer {
  border: 1px solid white;
  @apply h-4;
}

.pbPacket {
  @apply ml-[-1rem] mb-[-0.3rem];
}

.rotatePbfPacket {
  animation: rotateImageInfinite 1s linear infinite; /* Apply the animation */
}

.infiniteRotation {
  animation: rotateImageInfinite 0.65s linear infinite !important; /* Rotate forward and then backward */
}

.hide {
  @apply hidden;
}

@keyframes rotateImageInfinite {
  from {
    transform: rotate(0deg); /* Start rotation from 0 degrees */
  }
  to {
    transform: rotate(2160deg); /* Complete rotation */
  }
}

/* Define the animation */
@keyframes rotateImage {
  from {
    transform: rotate(0deg); /* Start rotation from 0 degrees */
  }
  to {
    transform: rotate(1440deg); /* End rotation at 360 degrees */
  }
}
