.root {
  @apply mb-10;
}
.colRowContainer {
  @apply p-3;
}

.colRowContainer .row {
  @apply w-full flex justify-between my-3;
}

.colRowContainer .row:nth-child(1) .col {
  @apply flex justify-center px-2 w-[46%] items-center;
}

.colRowContainer .row:nth-child(2) .col {
  @apply flex w-[45%] items-center justify-between mt-4 mb-3;
}

.colRowContainer .row .boxWidth {
  @apply w-[6.125rem] h-[2.75rem];
}
.colRowContainer .row .playerDetailCol {
  @apply w-[75%];
}

.playerDetailCol .colRowContainer .row:nth-child(2) {
  @apply border-b-[rgba(25,100,213,70%)] border-b-[1.5px] border-solid pb-[0.5rem];
}

.colRowContainer .row:nth-child(3) {
  @apply mt-5;
}

.plusMinusBox {
  @apply min-h-[9rem] w-[3rem] max-h-fit flex text-center items-center justify-center flex-col border-[1px] border-solid border-[rgba(25,100,213,70%)] bg-[#160F2A] rounded-lg px-2  shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)] shadow-[0px_0px_6px_0px_rgba(25,100,213,1)_inset];
}

.paddingSm {
  @apply px-4 min-h-[2.5rem];
}

.playerDetails {
  @apply flex items-center justify-start flex gap-[0.4rem];
}
.playerDetails .attRating {
  margin-right: 0.1rem;
  @apply w-[2rem] h-[2rem] flex flex-col items-center justify-center rounded-[0.2rem];
}
.playerDetails .teamDetails {
  @apply text-left w-[70%];
}

.playerDetails .teamDetails p:nth-child(1) {
  @apply mb-1;
}

.bgBronze {
  @apply bg-AttBgColor-bronze;
}
.bgSilver {
  @apply bg-AttBgColor-silver;
}
.bgGold {
  @apply bg-AttBgColor-gold;
}
.bgInForm {
  @apply bg-AttBgColor-inform;
}

.showMoreContainer {
  @apply flex items-center justify-center gap-2 pb-4;
}

.showMoreContainer > p {
  @apply font-avenirNextRegular text-white700;
}

.rotateIcon {
  @apply rotate-180;
}

.disable {
  display: none;
}

.disabledPointer {
  @apply pointer-events-none;
}
