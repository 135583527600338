.drawerContainer {
  @apply absolute w-full h-full max-w-[480px] z-40 bg-primary700 flex flex-col items-center overflow-hidden bg-[length:100%_100%];
}

.zIndexLevel1 {
  @apply !z-40;
}

.zIndexLevel2 {
  @apply !z-50;
}

.teamEditBg {
  @apply bg-teamEditBg;
}

.betpannelOptionsBg {
  @apply bg-betPannelOptionsWaterMarkBg bg-no-repeat;
}

.drawerPosition {
  @apply bottom-0 w-full transform h-auto;
}

.drawerPositionXl {
  @apply h-[calc(100%_-_2rem)] !top-[2rem];
}

.drawerPositionLg {
  @apply h-[calc(100%_-_8.2rem)] !top-[8.2rem];
}

.drawerPositionXs {
  @apply !top-[70%] h-[30%];
}

.drawerPositionXs2 {
  @apply !top-[30%] h-[70%] transition-all duration-[0.5s] ease-[ease-in-out];
}

.drawerTop {
  @apply top-0;
}
/* 
.drawerTop.show {
  @apply top-20;
} */

.drawerTopXl {
  @apply !top-0;
}

.drawerTopXl.show {
  @apply !top-[2rem];
}

.drawerTopLg {
  @apply !top-0;
}

.drawerTopLg.show {
  @apply !top-[8.2rem];
}

.drawerTopXs {
  @apply !top-0;
}

.drawerTopXs.show {
  @apply !top-[60%];
}

.slideInTop {
  /* @apply top-20 animate-slideInTop; */
  @apply animate-slideInTop;
}

.slideOutTop {
  @apply animate-slideOutTop pointer-events-none;
}

.slideInBottom {
  @apply animate-slideInBottom;
}

.slideOutBottom {
  @apply animate-slideOutBottom pointer-events-none;
}

.slideInLeft {
  @apply animate-slideInLeft;
}

.slideOutLeft {
  @apply animate-slideOutLeft pointer-events-none;
}

.slideInRight {
  @apply animate-slideInRight;
}

.slideOutRight {
  @apply animate-slideOutRight pointer-events-none;
}

.drawerContainerSub {
  @apply flex h-full flex-col w-full overflow-y-auto;
}

.headerNodeHidden > :nth-child(1) {
  @apply hidden;
}

.backButton {
  @apply pb-[4.5rem];
}

.smallBackButton {
  @apply pb-[3.2rem];
}

.backButton > :nth-child(3) {
  @apply absolute bottom-0 h-[4.5rem] flex flex-row items-center justify-center gap-x-2;
}

.smallBackButton > :nth-child(3) {
  @apply absolute bottom-0 h-[3.2rem] flex flex-row items-center justify-center gap-x-2;
}

@media (max-width: 380px) {
  .smallBackButton {
    @apply pb-[3rem];
  }
  .smallBackButton > :nth-child(3) {
    @apply absolute bottom-0 h-[3rem] flex flex-row items-center justify-center gap-x-2;
  }
}

.backButtonImage {
  @apply h-3 w-3;
}

.backButtonHidden > :nth-child(3) {
  @apply hidden;
}

.backButtonImgHidden {
  @apply hidden;
}

.showNavigationBar {
  @apply h-[calc(100%_-_9.5rem)];
}

.showNavigationBarLg {
  @apply h-[calc(100%_-_17.7rem)];
}

.contentFull {
  @apply h-full;
}

.contentScreen {
  @apply h-screen;
}

.hideEl {
  @apply !hidden;
}

.roundedTop {
  @apply rounded-t-lg;
}

.hide {
  @apply !hidden;
}

.hideVisibility {
  @apply !w-0;
}
