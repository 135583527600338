.root {
  border-radius: 0.5rem;
  border: 1px solid rgba(25, 100, 213, 70%);
}
.disableClick {
  pointer-events: none;
}
.childRoot {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out;
}

.childRoot.open {
  max-height: 55rem; 
}

.accordionIcon {
  @apply transition-all duration-[0.3s] ease-[ease-in-out];
}

.rotateAccordionIcon {
  @apply rotate-180;
}

.justifyBetween {
  @apply flex justify-between py-3 px-1 items-center;
}

.justifyBetween > p {
  @apply font-avenirNextMedium !text-base;
}

.backBtn {
  @apply h-full w-[0.5rem] -rotate-180;
}

.arrow {
  @apply transition-transform duration-[0.3s] rotate-90;
}
.accordionHeader {
  @apply bg-[rgba(25,100,213,15%)] overflow-hidden px-[1.2rem] py-[0.6rem] border-b-[rgba(25,100,213,20%)] border-b border-solid min-h-10 flex justify-between items-center;
}
