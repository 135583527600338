.root {
  @apply font-black text-white rounded-lg transition-all duration-[0.2s] ease-[ease] disabled:opacity-90;
}

.isShowClickTransition:active {
  transform: scale(0.99);
  box-shadow: 0 0 0 transparent;
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.isShowClickTransition:active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
}

.whiteBorder {
  @apply border-2 border-solid border-[#8E8797];
}

.greenBtn {
  @apply bg-lightGreen500  flex items-center justify-center text-black;
}

.darkGreenBtn {
  @apply !bg-lightGreen600;
}

.cheatingBtn {
  @apply !bg-red-600 text-white;
}

.redBtn {
  @apply !bg-red-700 flex items-center justify-center text-white;
}

.purpleBlueExtraLightBtn {
  @apply !bg-stakePannel-purpleBlue100;
}

.blueBtn {
  @apply hover:bg-primary800  bg-lightBlue400;
}

.storeBtnGreen {
  @apply !w-32 bg-lightGreen600 h-10 flex items-center justify-center shadow-sm;
}

.storeBtnDarkBlue {
  @apply !w-32 bg-primary800 h-10 flex items-center justify-center shadow-sm;
}

.transparentBtn {
  @apply bg-transparent;
}

.blackBtn {
  @apply bg-black hover:bg-black;
}

.navyBlue {
  @apply !bg-primary700 border-neutral500 border-2;
}

.gray {
  @apply border-neutral500;
}

.customStakeBtn {
  padding: 3vw 8vw !important;
}
.small {
  @apply w-36;
}

.medium {
  @apply w-40;
}

.large {
  @apply w-56;
}

.full {
  @apply w-full;
}

.stretch {
  @apply w-full;
}

.displayFlex {
  @apply flex justify-center items-center text-center;
}

.flexCol {
  @apply flex-col leading-[1px];
}
.addDropShadow {
  @apply shadow-StakePannelBtnShadow;
}

.hide {
  @apply !hidden;
}

.addExtraPadding {
  @apply py-4;
}

.addMinHeight {
  @apply min-h-14;
}

.mediumHeight {
  @apply min-h-12;
}

.noRoundedEdge {
  @apply rounded-[unset];
}

.disabled {
  @apply bg-stakePannel-purpleBlue100 pointer-events-none;
}

.isAddMoreBtnDisabled {
  background-color: black !important;
  @apply !bg-black pointer-events-none;
}

.isDisableOnClick {
  @apply !pointer-events-none;
}
