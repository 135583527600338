.root {
  @apply flex flex-col gap-4;
}

.text {
  @apply flex flex-col gap-2;
}

.tableRoot {
  @apply w-full overflow-auto;
}

table {
  @apply w-full table-fixed;
}

table th,
table td {
  @apply border border-neutral-500 py-1.5 text-center;
  width: 1%;
}

.data {
  @apply w-full flex flex-col;
}

.rows {
  @apply w-full flex flex-row justify-center text-center  border border-neutral-500;
}

.rows > :nth-child(1) {
  @apply w-1/2 p-2 border-r-2 border-neutral-500;
}
.rows > :nth-child(2) {
  @apply w-1/2 p-2 border-r-2 border-neutral-500;
}

.rows > :nth-child(2) {
  @apply w-1/2 p-2 break-words;
}
.rows > :nth-child(3) {
  @apply w-1/2 p-2 break-words;
}

.index1 > :nth-child(1) {
  @apply !font-avenirNextBold text-base;
}

.index1 > :nth-child(2) {
  @apply !font-avenirNextBold text-base;
}


.addBottomSpace {
  @apply mb-6;
}



/* team Table styling from here ... */

.tableContainer {
  width: 100%;
  max-width: 600px;
  border: 2px solid #737373;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.row {
  display: grid;
  grid-template-columns: 40px 120px 1fr;
  align-items: stretch;
  border-bottom: 2px solid #737373;
}

.row:last-child {
  border-bottom: none;
}

.iconCell {
  @apply flex items-start justify-center p-[10px];
}

.icon {
  @apply w-4;
}

.titleCell {
  @apply p-[10px] border-l-2 border-neutral-500 border-r-2 flex items-start justify-center;
}

.descriptionCell {
  @apply p-[10px];
}
